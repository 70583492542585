import Project from "./Project"

import foro from '../images/projects/foro.PNG'
import gymprime from '../images/projects/gymprime.PNG'
import instagram from '../images/projects/instagram.PNG'
import citas from '../images/projects/citas.png'
import money from '../images/projects/money.PNG'
import moneyNative from '../images/projects/money_native.PNG'
import cripto from '../images/projects/cripto.png'
import apiClientes from '../images/projects/api_clientes.png'


const About = () => {
    return (
        <div className=' bg-[#090909] text-white w-full h-full p-5 '>
            <div className="py-[7%] px-[10%] border-white border rounded">
                <h1 className="text-2xl md:text-4xl font-bold">Mis proyectos</h1>
                <div className="flex flex-col gap-2 mt-10 text-xl">
                    <p className="text-2xl">¿Que tengo planeado para el futuro?</p>
                    <p className="text-lg font-thin text-gray-100">Especializarme tanto en <span className="text-white font-normal">React Web</span> como en <span className="text-white font-normal">React Native</span>, juntandolo con tecnologias backend como <span className="text-white font-normal">Node</span> y hacer mis propias API.</p>
                </div>
                
                <div className="flex flex-col mt-20 gap-10">
                <h2 className="text-2xl border-b-[1px] border-b-gray-500 pb-2">Web</h2>

                    <Project 
                        imagen={money} 
                        titulo="Gestor de gastos" 
                        subtitulo="Hecho en React, te permite tener un control mayor sobre tu dinero" 
                    />

                    <Project 
                        imagen={cripto} 
                        titulo="API para Criptomonedas" 
                        subtitulo="Hecho en React, consume una API externa para poder ver estadisticas de diversas criptomonedas" 
                    />

                    <Project 
                        imagen={apiClientes} 
                        titulo="API Clientes" 
                        subtitulo="Hecho con Node y React, uso mi propia API para un CRUD de clientes" 
                    />

                    <Project 
                        imagen={instagram} 
                        titulo="Clon instagram" 
                        subtitulo="Hecho en Laravel, una red social" 
                    />

                    <Project 
                        imagen={gymprime} 
                        titulo="GymPrime" 
                        subtitulo="Hecho en Laravel, la comunidad podrá publicar sus rutinas de deporte para que otros puedan ponerlas a prueba" 
                    />

                    <Project 
                        imagen={foro} 
                        titulo="Foro informatico" 
                        subtitulo="Hecho en Laravel, un foro para subir tus dudas de programacion, incluye cuentas de usuario y roles" 
                    />



                <h2 className="text-2xl border-b-[1px] border-b-gray-500 pb-2 mt-20">Móvil</h2>

                    <Project 
                        imagen={citas} 
                        width="w-[350px]"
                        titulo="Gestor de Tareas" 
                        subtitulo="Hecho en React Native, ¡para no olvidar a tus amigos!" 
                    />

                    <Project 
                        imagen={moneyNative} 
                        width="w-[350px]"
                        titulo="Gestor de Gastos" 
                        subtitulo="Hecho en React Native, para tener un control mayor sobre tus gastos, ¡ahora en movil!" 
                    />

                </div>
            </div>
        </div>
      )
}

export default About
